<template>
  <div>
    <portal to="body-top">
      <b-skeleton-wrapper
        :loading="isLoading"
        class="d-flex justify-content-center"
      >
        <template #loading>
          <b-skeleton
            width="30%"
            height="100%"
            class="m-0"
          />
        </template>
        <h3 class="text-center font-weight-bolder mb-1">
          {{ $t(CREATE_PAGE_TITLE) }}
        </h3>
      </b-skeleton-wrapper>
    </portal>
    <quote-status-bar
      :item="order"
      :quote-status-bar="getStatusBar()"
      style="margin-bottom: 1rem"
    />
    <b-overlay
      variant="white"
      :show="isLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <scan-form />
    </b-overlay>
  </div>
</template>
<script>
import config from '@/views/main/orders/view/rental-sales/config'
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import { BOverlay, BSkeleton, BSkeletonWrapper } from 'bootstrap-vue'
import ScanForm from '@/views/main/orders/view/rental-sales/update/pages/scanItem/ScanForm.vue'

export default {
  name: 'ScanItem',
  components: {
    ScanForm,
    BOverlay,
    QuoteStatusBar,
    BSkeletonWrapper,
    BSkeleton,
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
    // titleOfDraft() {
    //   return `Quote ${this.order?.id || '-'} — ${
    //       this.order?.customer?.cust_name_dba || '-'
    //   }`
    // },
    // locationPathName() {
    //   return this.$route.name
    // },
    totalPrice() {
      return this.$store.state[this.MODULE_NAME].totalsForm.totalPrice
    },
  },
  // watch: {
  //   locationPathName() {
  //     this.fetch()
  //   },
  // },
  created() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.isLoading = true
      this.$store
        .dispatch(`${this.MODULE_NAME}/getDefaultCustomer`)
        .then(res => {
          const { data, success } = res.data
          if (success && data) {
            // Customer Information
            this.customerSearchQuery = res.data.data.customer.cust_name_dba
            this.$store.commit(
              `${this.MODULE_NAME}/SET_ORDERS_INFORMATION_FORM`,
              {
                customer_id: data.customer.id,
              },
            )
            this.$store.commit(
              `${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`,
              {
                order_rules_required_security_deposit:
                    data.order_rules_required_security_deposit,
                po_number: data.po_number,
                deposit_requirement: data.deposit_requirement,
                customer_discount: data.customer_discount,
                payer_account_id: data.payer_account_id,
                payer_email: data.payer_email,
                payment_term_id: data.payment_term_id && {
                  id: data.payment_term_id?.id,
                  label: data.payment_term_id?.label,
                },
                price_tier: data.customer?.customer_detail?.price_tier?.label,
                order_placed_by_id: data.order_placed_by && {
                  ...data.order_placed_by,
                  full_name: `${data.order_placed_by?.firstname} ${data.order_placed_by?.lastname}`,
                },
                receive_method: data?.receive_method && {
                  id: data?.receive_method,
                  name: data?.receive_method,
                },
                attachments: data.attachments,
              },
            )
            this.$store.commit(`${this.MODULE_NAME}/SET_PAYER_ACCOUNT`, {
              value: data.payer_account,
            })
            // Insurance Information
            this.$store.commit(
              `${this.MODULE_NAME}/SET_INSURANCE_INFORMATION_FORM`,
              {
                policy_number: data?.insurance_policy?.policy_number,
                amount_insured: data?.insurance_policy?.amount_insured,
                deductible: data?.insurance_policy?.deductible,
                coverage_start_date:
                        new Date(data?.insurance_policy?.coverage_start_date) * 1000,
                coverage_end_date:
                        new Date(data?.insurance_policy?.coverage_end_date) * 1000,
              },
            )
            // // Rental Order Dates
            // this.$store.commit(
            //   `${this.MODULE_NAME}/SET_EVENT_INFORMATION_FORM`,
            //   {
            //     event_information_dispatch_date:
            //             isNull(data?.event_information_dispatch_date) ? null : data?.event_information_dispatch_date * 1000,
            //     event_information_rental_end:
            //             isNull(data?.event_information_rental_end) ? null : data?.event_information_rental_end * 1000,
            //     event_information_rental_end_time:
            //             isNull(data?.event_information_rental_end_time) ? null : data?.event_information_rental_end_time,
            //     event_information_rental_start_dispatch:
            //             isNull(data?.event_information_rental_start_dispatch) ? null : data?.event_information_rental_start_dispatch * 1000,
            //     event_information_rental_start_dispatch_time:
            //             isNull(data?.event_information_rental_start_dispatch_time) ? null : data?.event_information_rental_start_dispatch_time,
            //     event_reference: data?.reference,
            //   },
            // )
            // Dispatch
            this.$store.commit(
              `${this.MODULE_NAME}/SET_DISPATCH_AND_RETURN_FORM`,
              {
                dispatch_address_id: data?.dispatch_address && {
                  id: data?.dispatch_address_id,
                  fullAddress: `${data?.dispatch_address?.street}, ${data?.dispatch_address?.city}, ${data?.dispatch_address?.state?.code}, ${data?.dispatch_address?.country?.three_letter}`,
                },
                dispatch_contact_id: data?.dispatch_contact && {
                  id: data?.dispatch_contact?.id,
                  fullName: `${data?.dispatch_contact?.first_name} ${data.dispatch_contact?.last_name}`,
                },
                dispatch_phone: data.dispatch_phone,
                dispatch_email: data.dispatch_email,
                return_address_id: data?.return_address && {
                  id: data?.return_address_id,
                  fullAddress: `${data?.return_address?.street}, ${data?.return_address?.city}, ${data?.return_address?.state?.code}, ${data?.return_address?.country?.three_letter}`,
                },
                return_contact_is_different: data.return_contact_is_different,
                internal_shipping_notes: data.internal_shipping_notes,
                return_method: data?.return_method,
                order_routing_allow_dispatch_of_items_from_warehouse_of_origin:
                    data.order_routing_allow_dispatch_of_items_from_warehouse_of_origin,
                order_routing_order_returns_to_another_warehouse:
                    data.order_routing_order_returns_to_another_warehouse,
                dispatch_warehouse_id: data?.dispatch_warehouse,
                dispatch_method: data?.dispatch_method,
                return_warehouse_id: data?.return_warehouse,
                return_contact_id: data?.return_contact && {
                  id: data?.return_contact?.id,
                  full_name: data?.return_contact?.fullName,
                },
                return_phone: data?.return_contact?.phone_one,
                return_email: data?.return_contact?.email,
              },
            )
            // Totals
            this.$store.commit(`${this.MODULE_NAME}/SET_TOTALS_FORM`, {
              total_order_notes: data.total_order_notes,
            })
            // Product Rental Items
            // const productsList = {
            //   inheritFromOrder: true,
            //   isNoteOpen: false,
            //   is_active: true,
            // }
            // const rental = data?.products
            //   ?.filter(item => item?.rental_sale_type === 'rent')
            //   .map(item => ({
            //     ...productsList,
            //     ...item,
            //     type: item.rental_sale_type,
            //     rental_start: item?.rental_start && getUnixTime(new Date(item?.rental_start)) * 1000,
            //     rental_end: item?.rental_end && getUnixTime(new Date(item?.rental_end)) * 1000,
            //     event_information_rental_start_time: item?.rental_start && format(new Date(item?.rental_start), 'HH:mm:00'),
            //     event_information_rental_end_time: item?.rental_end && format(new Date(item?.rental_end), 'HH:mm:00'),
            //     product_id: item.product_id,
            //     category: item.category,
            //   }))

            // const sales = data?.products
            //   ?.filter(item => item?.rental_sale_type === 'sale')
            //   ?.map(item => ({
            //     ...productsList,
            //     ...item,
            //     type: item.rental_sale_type,
            //     product_id: item.product_id,
            //   }))

            // const fieldName = {
            //   startDate: 'start_date',
            //   endDate: 'end_date',
            // }
            // this.calculatePriceOfProducts({
            //   productsList: rental,
            //   fieldName,
            //   storeActionKey: 'SET_ORDER_ITEMS_RENTAL_TABLE',
            // })
            //
            // this.calculatePriceOfProducts({
            //   productsList: sales,
            //   fieldName,
            //   storeActionKey: 'SET_ORDER_ITEMS_SALES_TABLE',
            // })
            // // Other charges items
            // this.calculatePriceOfProducts({
            //   productsList: data.other_charges?.map(charge => ({
            //     ...charge,
            //     amount: charge.unit,
            //   })),
            //   fieldName: {
            //     taxes: 'tax',
            //   },
            //   storeActionKey: 'SET_ORDER_ITEMS_OTHER_CHARGES_TABLE',
            // })

            // this.$store.dispatch(`${this.MODULE_NAME_PAYMENT}/getCardList`, data.customer.id)
            //   .then(response => {
            //     this.$store.commit(`${this.MODULE_NAME_PAYMENT}/SET_CARD_LIST`, response.data.data)
            //     this.$store.commit(`${this.MODULE_NAME_PAYMENT}/SET_PAYMENT_FORM`, {
            //       deposit: 0.00,
            //       charge: this.totalPrice,
            //     })
            //   })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getStatusBar() {
      if (this.order.state === 1) {
        this.quoteStatusBar[0].isShow = false
        this.quoteStatusBar[1].isShow = true
        return this.quoteStatusBar
      }
      this.quoteStatusBar[0].isShow = true
      this.quoteStatusBar[1].isShow = false
      return this.quoteStatusBar
    },
  },
  setup() {
    const {
      MODULE_NAME_PAYMENT = 'payment', MODULE_NAME, customerSearchQuery, calculatePriceOfProducts, quoteStatusBar, draftStatus, holdStatus, CREATE_PAGE_TITLE, ORDER_ITEMS_FORM_TITLE,
    } = config()

    return {
      MODULE_NAME_PAYMENT,
      MODULE_NAME,
      customerSearchQuery,
      calculatePriceOfProducts,
      quoteStatusBar,
      draftStatus,
      holdStatus,
      CREATE_PAGE_TITLE,
      ORDER_ITEMS_FORM_TITLE,
    }
  },
}
</script>
<style scoped lang="scss">

</style>
