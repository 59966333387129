<template>
  <div>
    <order-information
      ref="orderInformation"
      class="mb-1"
    />
    <form-piece-appear-on-customer-select />

    <div class="form-wrapper">
      <div>
        <div>
          <h3 class="font-weight-bolder mb-1">
            {{ $t(ORDER_ITEMS_FORM_TITLE) }}
          </h3>
        </div>
        <scan
          @getValue="getValue"
        />
        <sales-table
          ref="sale-table"
          search-endpoint="getAsset"
          :is-disabled-auto-suggest="true"
        />
      </div>
      <div>
        <other-charges-table />
      </div>
    </div>

    <dispatch-and-return class="mb-1" />

    <totals class="mb-1" />
    <form-bottom
      :is-update="true"
      :form-ref="$refs"
    />
  </div>
</template>
<script>
import FormBottom from '@/views/main/orders/view/rental-sales/components/scanItem/QuoteFooterButtons.vue'
import OtherChargesTable
from '@/views/main/orders/view/rental-sales/components/create/components/OtherChargesTable.vue'
import FormPieceAppearOnCustomerSelect
from '@/views/main/orders/view/rental-sales/components/create/FormPieceAppearOnCustomerSelect.vue'
import SalesTable from '@/views/main/catalog/view/components/SalesTable.vue'
import Totals from '@/views/main/orders/view/rental-sales/components/create/components/Totals.vue'
import DispatchAndReturn
from '@/views/main/orders/view/rental-sales/components/create/components/DispatchAndReturn.vue'
import Scan from '@/views/components/Scan/Scan.vue'
import OrderInformation from '@/views/main/orders/view/rental-sales/components/create/components/OrderInformation.vue'
// eslint-disable-next-line import/no-cycle
import config from '@/views/main/orders/view/rental-sales/config'

export default {
  name: 'ScanForm',
  components: {
    OrderInformation,
    Scan,
    DispatchAndReturn,
    Totals,
    SalesTable,
    FormPieceAppearOnCustomerSelect,
    OtherChargesTable,
    FormBottom,
  },
  methods: {
    getValue(value) {
      this.$store
        .dispatch(`${this.MODULE_NAME}/getAsset`, { search: value }).then(res => {
          const { data } = res.data
          console.log(data)
          this.$refs['sale-table'].selectHandler({ item: data })
        })
    },
  },
  setup() {
    const {
      MODULE_NAME_PAYMENT = 'payment', MODULE_NAME, customerSearchQuery, calculatePriceOfProducts, quoteStatusBar, draftStatus, holdStatus, CREATE_PAGE_TITLE, ORDER_ITEMS_FORM_TITLE,
    } = config()

    return {
      MODULE_NAME_PAYMENT,
      MODULE_NAME,
      customerSearchQuery,
      calculatePriceOfProducts,
      quoteStatusBar,
      draftStatus,
      holdStatus,
      CREATE_PAGE_TITLE,
      ORDER_ITEMS_FORM_TITLE,
    }
  },
}
</script>
<style lang="scss">
.l-tableList-collector .table-striped tbody tr:last-child:not(.b-table-empty-row) td:last-child{
  border-bottom-right-radius: 5px !important;
}
.l-tableList-collector .table-striped tbody tr:last-child:not(.b-table-empty-row) td:first-child{
  border-bottom-left-radius: 5px !important;
}
.l-tableList-collector .table-striped tbody tr:last-child:not(.b-table-empty-row) td{
  border-bottom: solid 1px #dde3e7 !important;
}
.autosuggest__results{
  min-width: max-content;
}
</style>
