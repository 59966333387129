<template>
  <portal to="body-footer">
    <div class="d-flex mt-2 pb-1 justify-content-between">
      <div
        class="d-flex"
        style="gap: 8px"
      >
        <b-button
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
          @click="$router.push({name: 'home-orders-rental-sales-list'})"
        >
          {{ $t('Back to List') }}
        </b-button>
      </div>
      <div
        class="d-flex"
        style="gap: 8px"
      >
        <div
          v-for="button in scanItemPageRightButtons"
          :key="button.title"
        >
          <b-overlay
            :show="loading.actionKey === button.actionKey && loading.progress"
            rounded
            opacity="0.6"
            spinner-small
            :spinner-variant="button.variant"
            class="d-inline-block"
          >
            <!--
              :disabled="loading.progress"-->
            <b-button
              :variant="button.variant"
              :class="button.classes"
              @click="submitAction(button.actionKey)"
            >
              <feather-icon
                :v-if="button.icon"
                :icon="button.icon ? button.icon.path : ''"
                :size="button.icon ? button.icon.size : ''"
              />
              {{ $t(button.title) }}
            </b-button>
          </b-overlay>
        </div>

      </div>
    </div>
  </portal>
</template>
<script>
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import { BButton, BOverlay } from 'bootstrap-vue'
import html2canvas from 'html2canvas'
import { isNull } from 'lodash'
import { format } from 'date-fns'
import { mapGetters } from 'vuex'
import jsonToFormData from '@/libs/jsonToFormData'
import config from '../create/config'
import mainConfig from '../../config'

export default {
  name: 'QuoteFooterButtons',
  components: {
    BOverlay,
    BButton,
  },
  props: {
    formRef: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: {
        actionKey: '',
        progress: false,
      },
    }
  },
  computed: {
    ...mapGetters('rental-sales', ['getTotalDataOfForms']),
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
    total_order_notes() {
      return this.$store.state[this.MODULE_NAME].totalsForm.total_order_notes
    },
    internal_shipping_notes() {
      return this.$store.state[this.MODULE_NAME].dispatchAndReturnForm.internal_shipping_notes
    },
    serviceOrderId() {
      return this.$store.state[this.MODULE_NAME].order.service_order_id
    },
    isVoid() {
      return this.$store.state[this.MODULE_NAME].order.status === 5
    },
  },
  methods: {
    isNull,
    isShowButton(b) {
      if (this.isVoid) {
        return b.isShowButtonIfVoidOrder
      }
      if (!isNull(this.serviceOrderId)) {
        return b.isShowButtonIfForServiceOrder
      }
      return true
    },
    printCanvas() {
      const appContentEl = document.getElementById('app-content')

      html2canvas(appContentEl).then(canvas => {
        const printWindow = window.open('', '_blank')
        const printDocument = printWindow.document

        printDocument.write(`
        <html>
          <head>
            <title>Print Canvas</title>
            <style>
              body { margin: 0 15%;height: 100%; }
              canvas { height: 100%; width: 100%;}
            </style>
          </head>
          <body>
            <canvas id="printCanvas" style="display:block;"></canvas>
          </body>
        </html>
      `)

        const printCanvas = printDocument.getElementById('printCanvas')
        printCanvas.width = canvas.width
        printCanvas.height = canvas.height

        const printCtx = printCanvas.getContext('2d')
        printCtx.drawImage(canvas, 0, 0)

        printWindow.print()
        printWindow.close()
      })
    },
    submitAction(action) {
      switch (action) {
        case this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PRINT:
          this.printCanvas()
          break
        case this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DISPATCH:
          this.dispatch()
          break
        case this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PAY:
          this.pay()
          break
        default:
          this.$toast.error('Unsupported action')
      }
    },
    getFormData() {
      const totalDataOfForms = this.getTotalDataOfForms
      console.log(totalDataOfForms)
      return {
        customer_id: totalDataOfForms.customer_id,
        billing_contact_id: totalDataOfForms.billing_contact_id?.id,
        po_number: totalDataOfForms.po_number,
        payer_account: totalDataOfForms.payer_account,
        payer_account_id: totalDataOfForms.payer_account_id?.id,
        payer_email: totalDataOfForms.payer_email,
        payment_term_id: totalDataOfForms.payment_term_id?.id,
        customer_discount: totalDataOfForms.customer_discount,
        order_rules_required_security_deposit: totalDataOfForms.order_rules_required_security_deposit ? 1 : 0,
        order_placed_by_id: totalDataOfForms.order_placed_by_id?.id,
        receive_method: totalDataOfForms.receive_method?.id,
        dispatch_warehouse_id: totalDataOfForms.dispatch_warehouse_id?.id,
        dispatch_method: totalDataOfForms.dispatch_method?.id,
        return_method: totalDataOfForms.return_method?.id,
        order_routing_order_returns_to_another_warehouse: totalDataOfForms.order_routing_order_returns_to_another_warehouse ? 1 : 0,
        order_routing_allow_dispatch_of_items_from_warehouse_of_origin: totalDataOfForms.order_routing_allow_dispatch_of_items_from_warehouse_of_origin ? 1 : 0,
        return_warehouse_id: totalDataOfForms.return_warehouse_id?.id,
        internal_shipping_notes: totalDataOfForms.internal_shipping_notes,
        return_contact_is_different: totalDataOfForms.return_contact_is_different ? 1 : 0,
        dispatch_contact_id: totalDataOfForms.dispatch_contact_id?.id,
        return_contact_id: totalDataOfForms.return_contact_id?.id,
        dispatch_address_id: totalDataOfForms.dispatch_address_id?.id,
        return_address_id: totalDataOfForms.return_address_id?.id,
        dispatch_phone: totalDataOfForms.dispatch_phone,
        dispatch_email: totalDataOfForms.dispatch_email,
        event_information_dispatch_date: totalDataOfForms?.event_information_dispatch_date && format(totalDataOfForms?.event_information_dispatch_date, 'yyyy-MM-dd'),
        event_information_rental_start_and_dispatch_time: totalDataOfForms?.event_information_rental_start_dispatch && `${format(totalDataOfForms?.event_information_rental_start_dispatch, 'yyyy-MM-dd')} ${totalDataOfForms?.event_information_rental_start_dispatch_time?.substring(0, 5)}`,
        event_information_rental_end_and_receive_time: totalDataOfForms?.event_information_rental_end && `${format(totalDataOfForms?.event_information_rental_end, 'yyyy-MM-dd')} ${totalDataOfForms?.event_information_rental_end_time?.substring(0, 5)}`,
        event_reference: totalDataOfForms.event_reference,
        total_order_notes: totalDataOfForms.total_order_notes,
        // attachments: totalDataOfForms.attachments.filter(({ id }) => !id),

        products: totalDataOfForms.products?.map(product => ({
          product_id: product?.product_id,
          amount: product?.amount,
          type: product.type,
          notes: product?.notes,
        })),
        other_charges: totalDataOfForms?.other_charges?.filter(i => (i?.id && i?.product_id))?.map(i => ({
          unit: i.amount,
          product_id: this.isUpdate ? i.product_id : i.id,
        })),
      }
    },
    dispatch() {
      const payload = this.getFormData()
      return this.sendNotification(this, payload, `${this.MODULE_NAME}/directStore`).then(res => {
        const { data } = res
        this.$router.push({
          name: 'approve-quote-information',
          params: { id: data.id },
        })
      }).catch(err => {
        this.errorNotification(this, err)
      })
    },
    pay() {
      this.formRef.payment()
    },
  },
  setup() {
    const {
      EVENT_INFORMATION_TITLE,
      eventInformationObjectForRender,
    } = config()

    const {
      MODULE_NAME,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_CHANGE_QUOTE,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PRINT,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PAY,
      FORM_BUTTON_TITLE_DISPATCH,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DISPATCH,
      storeMutationsList,
      approvePageRightButtons,
      rightSideHoldReadyButtons,
      approvePageLeftButtons,
      scanItemPageRightButtons,
    } = mainConfig()

    return {
      MODULE_NAME,
      EVENT_INFORMATION_TITLE,
      getValueFromGivenObjectByKey,
      eventInformationObjectForRender,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PRINT,
      FORM_BUTTON_TITLE_DISPATCH,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DISPATCH,
      approvePageRightButtons,
      rightSideHoldReadyButtons,
      approvePageLeftButtons,
      storeMutationsList,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_CHANGE_QUOTE,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PAY,
      scanItemPageRightButtons,

    }
  },
}
</script>

<style scoped>
.bgRed{
  background-color: #6F1214 !important;
}
</style>
